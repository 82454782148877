import React, { useState, useRef } from 'react'
import {
  useLocation,
  getStoredCity,
  sendDataToGTM,
  useAuth,
  useShoppingCart,
  cleanOrderId,
  cityChangeMessage,
} from '@ecommerce/shared'
import { LocationSelectorModal } from './LocationSelectorModal'
import { useLocationSelectorOptions, LocationSelectorOption } from '../../graphql/global/locationOptions'
import { useUtmQueryParams } from '../../hooks/useUtmQueryParams'
import useSession from '../../hooks/useSession'
import ConfirmationAlert from '../ConfirmationAlert'
import { useCartRefresh } from '../../hooks/useCartRefresh'

interface LocationSelectorWidgetProps {
  onClose: () => void
  requireConfirmation?: boolean
  redirectQueryParams?: string
  hasCloseButton?: boolean
  excludeExternal?: boolean
  onSelect?: (option: LocationSelectorOption) => void
  isPrehome?: boolean
}

export const LocationSelectorWidget = ({
  onClose,
  requireConfirmation,
  redirectQueryParams,
  hasCloseButton,
  excludeExternal = false,
  onSelect,
  isPrehome,
}: LocationSelectorWidgetProps) => {
  const {
    state: { currentCity, byHashCities },
  } = useLocation()
  const {
    state: { isAuth: isAuthenticated },
  } = useAuth()
  const { resetAppState } = useSession()

  const isAuth = useRef(isAuthenticated).current

  const {
    state: { byHash: cart },
  } = useShoppingCart()

  const cartHasProducts = useRef(Object.keys(cart).length).current

  const [hasOpenConfirmation, setHasOpenConfirmation] = useState(false)
  const [selection, setSelection] = useState<LocationSelectorOption | null>(null)

  const city = currentCity ?? getStoredCity() ?? undefined

  const locationOptions = useLocationSelectorOptions().filter((location) => !(excludeExternal && location.isExternal))

  const { appendUtmQuery } = useUtmQueryParams()

  const { refreshCart } = useCartRefresh()

  const cityChange = async (option: LocationSelectorOption) => {
    sendDataToGTM({ event: 'registrationCity', cityName: option.title })
    if (city && option.slug === city.slug) return onClose()
    if (!option.id) return

    const redirectSlug = appendUtmQuery(`/${option.slug}?${redirectQueryParams ?? ''}`)
    const selectedCity = byHashCities[option.id]

    await refreshCart(selectedCity)

    await resetAppState(redirectSlug, {
      keepCart: true,
      keepAuth: true,
      newCity: selectedCity,
      onFinish: () => {
        setHasOpenConfirmation(false)
        onClose()
      },
    })
    cleanOrderId()
  }

  const getConfirmationText = () => {
    if (!requireConfirmation) return ''
    if (cartHasProducts) return cityChangeMessage
  }

  const onConfirmDefault = (option: LocationSelectorOption) => {
    if (requireConfirmation) {
      if (isAuth || cartHasProducts) {
        if (city && city.slug !== option.slug) {
          setHasOpenConfirmation(true)
          setSelection(option)
        } else onClose()
      } else cityChange(option)
    } else {
      cityChange(option)
    }
  }

  const onConfirm = onSelect ?? onConfirmDefault

  const onExternal = (option: LocationSelectorOption) => {
    window.location.href = option.slug
  }

  const locationSelectorProps = {
    locationOptions,
    currentCity: city,
    onClose,
    onConfirm,
    onExternal,
    hasCloseButton,
    isPrehome,
  }

  return (
    <>
      <LocationSelectorModal {...locationSelectorProps} />
      {hasOpenConfirmation && (
        <ConfirmationAlert
          onClose={() => setHasOpenConfirmation(false)}
          onConfirm={() => {
            setHasOpenConfirmation(false)
            onClose()
          }}
          onCancel={() => (selection ? cityChange(selection) : null)}
          confirmButtonText="Mantener ciudad"
          cancelButtonText="Cambiar"
          text={getConfirmationText()}
        />
      )}
    </>
  )
}
