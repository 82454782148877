import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { ModalBackground } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

const cssPrefix = `Alert__`
const ModalWrapper = styled.div`
  .${cssPrefix} {
    &background {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &modal {
      width: 320px;
      padding: 28px;
      background: ${(props) => props.theme.colors.white};
      border-radius: ${(props) => props.theme.borderRadius};

      &-top-bar {
        height: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &-close-icon {
          cursor: pointer;
        }
      }
    }
  }
`
export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
  zIndex?: string
  onClose?: () => void
  onBlur?: () => void
  backgroundClassName?: string
  className?: string
}

const Alert = (props: AlertProps) => {
  const { className, onClose, ...restProps } = props
  return (
    <ModalWrapper data-testid="alert">
      <ModalBackground
        onBlur={props.onBlur}
        zIndex={props.zIndex}
        className={`${cssPrefix}background ${props.backgroundClassName}`}
      >
        <div className={`${cssPrefix}modal ${className || ''}`} {...restProps}>
          <div className={`${cssPrefix}modal-top-bar`}>
            {onClose && (
              <Icon
                iconId="close"
                size="24"
                className={`${cssPrefix}modal-top-bar-close-icon`}
                onClick={() => onClose()}
                data-testid="alert-close-icon"
              />
            )}
          </div>
          {props.header && <div className={`${cssPrefix}header`}>{props.header}</div>}
          {props.body && <div className={`${cssPrefix}body`}>{props.body}</div>}
          {props.footer && <div className={`${cssPrefix}footer`}>{props.footer}</div>}
        </div>
      </ModalBackground>
    </ModalWrapper>
  )
}

export default Alert
