import React, { useState } from 'react'
import styled from 'styled-components'
import { use100vh } from 'react-div-100vh'
import {
  ModalBackground,
  toCssPrefix,
  breakpoints,
  Anchor,
  City,
  Button,
  hasScrollbarStyles,
  Select,
} from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'
import { LocationSelectorOption } from '../../graphql/global'
import secrets from '../../config/secrets'

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  img {
    width: 120px;
  }
  @media (${breakpoints.tabletPortrait.min}) {
    display: none;
  }
`

const Logo = (
  <LogoWrapper>
    <img
      src="https://images.ctfassets.net/16npdkkoi5mj/5DTF4Po0EexHavHJLoeXxz/6d5068dfd1ff02529cd6d2263c476568/Logococamicuenta.svg"
      alt="logo"
    />
  </LogoWrapper>
)

const GMAPS_URL = secrets.GATSBY_GOOGLE_MAPS_URL_EMBED

const { cssPrefix, toPrefix } = toCssPrefix('LocationSelector__')

const Wrapper = styled.div<{ viewportHeightCss: string; hasSelection?: boolean; isPrehome?: boolean }>`
  .${cssPrefix} {
    &background {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &modal {
      height: ${({ viewportHeightCss }) => viewportHeightCss};
      width: 100vw;
      background: ${({ theme }) => theme.colors.white};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: ${({ theme }) => theme.borderRadius};
    }
    &header {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
      &-row {
        min-height: 72px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-title,
      &-close {
        height: 100%;
      }
      &-title {
        flex-grow: 1;
        padding-left: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        &-desktop {
          display: none;
        }
      }
      &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
      }
    }
    &body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      &-dropdown {
        padding: 27px 0;
        width: 290px;
        text-align: center;
        .dropdown {
          margin-top: 10px;
          .Select__handle {
            height: 56px;
            span {
              color: ${({
                hasSelection,
                theme: {
                  colors: { blackMedium, black },
                },
              }) => (hasSelection ? black : blackMedium)};
            }
            .Select__change-text {
              padding-top: 5px;
              color: ${({ theme }) => theme.colors.blackMedium};
            }
          }
          .Select__options {
            margin-top: 7px;
            max-height: calc(${({ viewportHeightCss }) => viewportHeightCss} - 285px);
          }
          .Select__option-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 3px;
            .checked-icon {
              margin-top: -3px;
            }
          }
        }
      }
      &-map {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;
        &-title {
          text-align: center;
          color: ${({ theme }) => theme.colors.primary};
        }
        &-frame {
          flex-grow: 1;
          margin: 10px 0 20px 0;
        }
      }
    }
    &footer {
      height: 70px;
      &-confirm {
        width: 290px;
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &body {
        &-dropdown {
          width: 367px;
        }
      }
    }
  }
  @media (${({ isPrehome }) => (isPrehome ? breakpoints.tabletPortrait.min : breakpoints.desktop.min)}) {
    .${cssPrefix} {
      &modal {
        min-height: 500px;
        height: calc(${({ isPrehome }) => (isPrehome ? '70vh' : '100vh')} - 120px);
        width: 590px;
        margin-bottom: 25px;
      }
      &header {
        &-title {
          &-desktop {
            display: block;
          }
          &-mobile {
            display: none;
          }
        }
      }
      &body {
        &-dropdown {
          width: 384px;
          &-title {
            display: none;
          }
          .dropdown {
            margin-top: 0;
            .Select__options {
              ${hasScrollbarStyles};
              max-height: calc(${({ viewportHeightCss }) => viewportHeightCss} - 390px);
            }
          }
        }
      }
      &footer {
        &-confirm {
          width: 182px;
        }
      }
    }
  }
`

interface LocationSelectorModalProps {
  locationOptions: LocationSelectorOption[]
  currentCity?: City
  onClose?: () => void
  onExternal?: (option: LocationSelectorOption) => void
  onConfirm?: (option: LocationSelectorOption) => void
  hasCloseButton?: boolean
  isPrehome?: boolean
}

export const LocationSelectorModal = ({
  onClose,
  locationOptions,
  currentCity,
  onExternal,
  onConfirm,
  hasCloseButton = true,
  isPrehome,
}: LocationSelectorModalProps) => {
  const [selected, setSelected] = useState<LocationSelectorOption | null>(null)
  const [selectIsExpanded, setSelectIsExpanded] = useState(true)
  const viewportHeight = use100vh()
  const title = (
    <>
      <b className={toPrefix('header-title-desktop')}>Selecciona y confirma ubicación de despacho</b>
      <b className={toPrefix('header-title-mobile')}>Ubicación de despacho</b>
    </>
  )

  const onSelect = (option: LocationSelectorOption) => {
    if (option.isExternal && onExternal) return onExternal(option)
    setSelected(option)
  }

  const selectProps = {
    options: locationOptions.map((option) => ({ label: option.title, value: option })),
    placeholder: currentCity?.name ?? 'Seleccionar',
    isExpanded: true,
    onSelect,
    className: 'dropdown',
    changeText: <Icon iconId={selectIsExpanded ? 'chevron_up' : 'chevron_down'} size="18" />,
    onOptionsToggle: setSelectIsExpanded,
  }

  const confirmIsDisabled = !selected || selected.isExternal
  const confirm = () => {
    if (confirmIsDisabled) return
    if (onConfirm && selected) return onConfirm(selected)
  }

  return (
    <Wrapper
      {...{ viewportHeightCss: viewportHeight ? `${viewportHeight}px` : '100vh', hasSelection: !!selected, isPrehome }}
    >
      <ModalBackground className={toPrefix('background')}>
        <div className={toPrefix('modal')}>
          <div className={toPrefix('header')}>
            {isPrehome && Logo}
            <div className={toPrefix('header-row')}>
              <div className={toPrefix('header-title')}>{title}</div>
              <div className={toPrefix('header-close')}>
                {hasCloseButton && (
                  <Anchor onClick={() => (onClose ? onClose() : null)}>
                    <Icon iconId="close" size="25" />
                  </Anchor>
                )}
              </div>
            </div>
          </div>
          <div className={toPrefix('body')}>
            <div className={toPrefix('body-dropdown')}>
              <b className={toPrefix('body-dropdown-title')}>Selecciona y confirma ciudad</b>
              <Select {...selectProps} dataTest="city-selector" />
            </div>
            <div className={toPrefix('body-map')}>
              {selected && selected.googleEmbedId && (
                <>
                  <b className={toPrefix('body-map-title')}>Zona de Cobertura</b>
                  <iframe
                    className={toPrefix('body-map-frame')}
                    title="location-selector-map"
                    src={`${GMAPS_URL}${selected.googleEmbedId}`}
                    frameBorder="0"
                  />
                </>
              )}
            </div>
          </div>
          <div className={toPrefix('footer')}>
            <Button
              data-testid="button-confirm-city"
              isDisabled={confirmIsDisabled}
              className={toPrefix('footer-confirm')}
              onClick={() => confirm()}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </ModalBackground>
    </Wrapper>
  )
}
