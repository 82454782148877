import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from '@ecommerce/shared'
import Alert, { AlertProps } from '../Alert'

const cssPrefix = 'ConfirmationAlert__'

const sharedButtonStyles = css`
  width: 100%;
`

const BodyWrapper = styled.div`
  width: 100%;
  padding-top: 25px;
  .${cssPrefix} {
    padding-top: 23px;
    width: 100%;
    &text {
      margin-bottom: 25px;
      span {
        font-weight: bold;
        letter-spacing: -0.24px;
        font-size: 17px;
      }
    }
    &buttons {
      width: 100%;
      &-confirm {
        ${sharedButtonStyles}
        margin-bottom: 8px;
      }
      &-cancel {
        ${sharedButtonStyles}
      }
    }
  }
`

export interface ConfirmationAlertProps extends AlertProps {
  text?: string | React.ReactNode
  confirmButtonText: string
  cancelButtonText?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  onClose?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onBlur?: () => void
  onConfirm?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCancel?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
}

const ConfirmationAlert = (props: ConfirmationAlertProps) => {
  const { onConfirm, onCancel, text, confirmButtonText, cancelButtonText, className, ...restProps } = props
  const body = (
    <BodyWrapper className={className}>
      {text && <div className={`${cssPrefix}text`}>{typeof text === 'string' ? <span>{text}</span> : text}</div>}
      <div className={`${cssPrefix}buttons`}>
        <Button className={`${cssPrefix}buttons-confirm`} onClick={onConfirm} data-text="confirm-button">
          {confirmButtonText}
        </Button>
        <Button
          data-test="cancel-button"
          btnType="secondary"
          className={`${cssPrefix}buttons-cancel`}
          onClick={onCancel || props.onClose}
        >
          {cancelButtonText || 'Cancelar'}
        </Button>
      </div>
    </BodyWrapper>
  )
  return <Alert {...restProps} body={body} className={className} />
}

export default ConfirmationAlert
